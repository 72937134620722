














































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "@/store";
// components
import PdfViewer from "@/components/PdfViewer.vue";
import featureNotAvailable from "@/components/FeatureNotAvailable.vue";
import LoadingTextWave from "@/components/LoadingTextWave.vue";
// types
import { report, reportType } from "@/types/reports";
// modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import ReportsModule from "@/store/clients/Reports.module";
// utilities
import dateHelper from "Utilities/date-helper";
import stringHelper from "Utilities/string-helper";

const Vessels = getModule(VesselsModule, store);
const Reports = getModule(ReportsModule, store);

@Component({
  components: {
    PdfViewer,
    featureNotAvailable,
    LoadingTextWave,
  },
})
export default class Report extends Vue {
  loadingView = true;
  noReportAvailable = false;
  vessel = Vessels.currentVessel;
  selectedReportTypeId = 1;
  report: report | null = null;
  pdfSrc = "";
  selectedDate = "";
  calendarMenu = false;
  selectedReportIndex = -1;
  pdfLoader = false;

  @Watch("selectedReportTypeId")
  onReportTypeChange(): void {
    this.report = null;
    this.checkForReportsOnDate();
    this.setReportToMostRecent();
  }

  @Watch("selectedDate")
  onDateChange(): void {
    this.checkForReportsOnDate();
    this.calendarMenu = false;
  }

  @Watch("report")
  async onReportChange(report: report): Promise<void> {
    if (!report) return;
    this.pdfLoader = true;
    this.pdfSrc = await Reports.getReportUrl(report.id);
  }

  async created(): Promise<void> {
    await Reports.refreshReportTypes();

    if (Vessels.currentVessel) await Reports.refreshReports(Vessels.currentVessel.id);

    this.setReportToMostRecent();
    this.loadingView = false;
  }

  setReportToMostRecent(): void {
    if (this.reportsFilteredByType.length === 0) {
      this.noReportAvailable = true;
    } else {
      const mostRecentReport = this.reportsFilteredByType.slice(-1)[0];
      this.selectedDate = mostRecentReport.reportDateTime.substring(0, 10);
      this.report = mostRecentReport;
      this.selectedReportIndex = this.datesWithReportOfSelectedType.length - 1;
    }
  }

  checkForReportsOnDate(): void {
    const reportDateIndex = this.reportsFilteredByType.findIndex(report => this.formatReportDate(report) === this.selectedDate);

    if (reportDateIndex >= 0) {
      const reports = this.reportsFilteredByType.filter(report => report.reportDateTime.substr(0, 10) === this.selectedDate);

      this.selectedReportIndex = reportDateIndex;
      this.report = reports[0];
      this.noReportAvailable = false;
    } else {
      this.report = null;
      this.pdfSrc = "";
      this.selectedReportIndex = -1;
      this.noReportAvailable = true;
    }
  }

  switchDate(type: string): void {
    switch (type) {
      case "last":
        this.selectedDate = this.datesWithReportOfSelectedType[this.datesWithReportOfSelectedType.length - 1];
        break;
      case "prev":
        this.selectedDate = this.datesWithReportOfSelectedType[this.selectedReportIndex - 1];
        break;
      case "next":
        let i = this.selectedReportIndex;

        while (this.datesWithReportOfSelectedType[i] === this.selectedDate) {
          i++;
        }

        this.selectedDate = this.datesWithReportOfSelectedType[i];
        break;
      case "first":
        this.selectedDate = this.datesWithReportOfSelectedType[0];
        break;
    }
  }

  formatReportDate(report: report): string {
    return report.reportDateTime.substr(0, 10);
  }

  get reportsFilteredByType(): report[] {
    return Reports.reports.filter(report => {
      return report.reportTypeId === this.selectedReportTypeId;
    });
  }

  get featureEnabled(): boolean {
    if (!this.vessel) return false;
    return this.vessel.features.some(feature => feature.name === "Reports");
  }

  get datesWithReportOfSelectedType(): string[] {
    return this.reportsFilteredByType.map(report => {
      return this.formatReportDate(report);
    });
  }

  get multipleReportsFromSameDate(): { name: string; report: report }[] {
    const reportTypes = Reports.reportTypes;
    const ksaInputReportTypeId = reportTypes.find(reportType => reportType.reportTypeName === "KSA Input")?.id;
    const ksaResultReportTypeId = reportTypes.find(reportType => reportType.reportTypeName === "KSA Result")?.id;

    if (this.report?.reportTypeId === ksaInputReportTypeId || this.report?.reportTypeId === ksaResultReportTypeId) {
      // Get all ksa reports from selected date. Ksa-reports are filtered from
      // other reports becuase we need to separate input and output reports.
      const ksaReports = Reports.reports.filter(report => {
        return report.reportDateTime.substr(0, 10) === this.selectedDate && (report.reportTypeId === ksaInputReportTypeId || report.reportTypeId === ksaResultReportTypeId);
      });

      return ksaReports.map(report => {
        const reportObject = { name: "", report: report };

        report.reportTypeId === ksaInputReportTypeId
          ? (reportObject.name = report.reportDateTime.substring(11, 16) + " - Input")
          : (reportObject.name = report.reportDateTime.substring(11, 16) + " - Result");

        return reportObject;
      });
    } else {
      const reports = Reports.reports.filter(report => {
        return report.reportDateTime.substr(0, 10) === this.selectedDate && report.reportTypeId === this.selectedReportTypeId;
      });

      return reports.map(report => {
        const reportObject = { name: report.reportDateTime.substring(11, 16), report: report };

        return reportObject;
      });
    }
  }

  get reportTypesAsListItems(): reportType[] {
    let isKsaAdded = false;
    const selectItems: reportType[] = [];

    Reports.reportTypes.forEach(type => {
      if (stringHelper.stringContains(type.reportTypeName, "ksa")) {
        if (isKsaAdded) return;

        // Clone the object as we don't want to change the name in the store.
        const reportType = JSON.parse(JSON.stringify(type));
        reportType.reportTypeName = "KSA";
        isKsaAdded = true;

        selectItems.push(reportType);
      } else {
        if (type.reportTypeName === "Shapoli") return;

        selectItems.push(type);
      }
    });

    return selectItems;
  }

  get noReportMessage(): string {
    return this.noReportsOfSelectedType
      ? `Found no <b>${this.selectedReportType}</b> reports for this vessel.`
      : `Found no <b>${this.selectedReportType}</b> report for selected date. Try selecting a different date, or choose an other report type.`;
  }

  get pdfTitle(): string {
    return this.selectedReportType + " report: " + this.selectedReportDateFormatted;
  }

  get pdfDownloadUrl(): string {
    return "/Reports/" + this.report?.id + "?userDownloadRequest=true";
  }

  get selectedDateFormatted(): string {
    return dateHelper.getFormatedDateString(this.selectedDate);
  }

  get selectedReportDateFormatted(): string {
    return this.report ? dateHelper.getFormatedDateString(this.report.reportDateTime) : "-";
  }

  get selectedReportType(): string | undefined {
    return this.reportTypesAsListItems.find(reportType => reportType.id === this.selectedReportTypeId)?.reportTypeName;
  }

  get hasNextReport(): boolean {
    return this.selectedReportIndex >= 0 && this.datesWithReportOfSelectedType.length > this.selectedReportIndex + 1;
  }

  get hasPrevReport(): boolean {
    return this.selectedReportIndex > 0;
  }

  get isLastReport(): boolean {
    return this.selectedReportIndex === this.datesWithReportOfSelectedType.length - 1;
  }

  get isFirstReport(): boolean {
    return this.selectedReportIndex === 0;
  }

  get noReportsOfSelectedType(): boolean {
    return this.reportsFilteredByType.length < 1;
  }
}
